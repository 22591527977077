.calendarFilter {
    min-width: 25rem;
    position: absolute;
    background: whitesmoke;
    padding: 1rem;
    min-height: 25rem;
    z-index: 999;
}

.view {
    /* display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     margin: 1rem auto;
     width: 90%;
     max-width: 40rem;
     min-height: 10rem;
     border: 1px solid #ccc;
     padding: 1rem;
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
     background-color: white;
     border-radius: 6px;

 */
    /* background-color: whitesmoke;
     padding: 1rem;
     min-width: 25rem;
     min-height: 25rem;
     position: absolute;*/

}

.slotLabel {
    /* font-weight: bold;
     padding: 0.5rem;
     font-size: 1.2rem;
 */
}

.nowIndicator {
    /*
        background-color: #ff0000;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        opacity: 0.5;*/

}

.allDay {
    /* display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     margin: 1rem auto;
     width: 90%;
     max-width: 40rem;
     min-height: 10rem;
     border: 1px solid #ccc;
     padding: 1rem;
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
     background-color: white;
     border-radius: 6px;*/
}

.dayHeader {
    /*  display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-weight: bold;
      font-size: 1.2rem;*/
}

.dayHeader button {
    /*font: inherit;
    cursor: pointer;
    background-color: #8b005d;
    border: 1px solid #8b005d;
    color: white;
    padding: 0.25rem 1rem;*/
    border-radius: 6px;
}

.dayCell {
    /* display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     margin: 1rem auto;
     width: 90%;
     max-width: 40rem;
     min-height: 10rem;
     border: 1px solid #ccc;
     padding: 1rem;
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
     background-color: white;
     border-radius: 6px;*/
}

.slotLane {
    /*  display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 1rem auto;
      width: 90%;
      max-width: 40rem;
      min-height: 10rem;
      border: 1px solid #ccc;
      padding: 1rem;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
      background-color: white;
      border-radius: 6px;*/
}

.weekNumber {
    /* display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     margin: 1rem auto;
     width: 90%;
     max-width: 40rem;
     min-height: 10rem;
     border: 1px solid #ccc;
     padding: 1rem;
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
     background-color: white;
     border-radius: 6px;
 */
}

.event {
    /*display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
    width: 90%;
    max-width: 40rem;
    min-height: 10rem;
    border: 1px solid #ccc;
    padding: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    background-color: white;
    border-radius: 6px;*/
}

.moreLink {
    /* display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     margin: 1rem auto;
     width: 90%;
     max-width: 40rem;
     min-height: 10rem;
     border: 1px solid #ccc;
     padding: 1rem;
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
     background-color: white;
     border-radius: 6px;*/
}
